import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import img2 from '../../img/thumbnail_cat.jpg'; // Update the path to your thumbnail image
import img1 from '../../img/thumbnail_titans.jpg';
import img3 from '../../img/thumbnail_iamsecurity.jpg'; // Update the path to your thumbnail image
import playButtonImg from '../../img/play-button.png'; // Add the path to your play button image
import './style.css';

const Media = () => {
  return (
    <>
      <section className="video-section section_t_100">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <p>Media</p>
                <h2>
                  Watch <span>Trailers</span><br />
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            
            {/* I Am Cat Trailer */}
            <Col lg={6} md={6}>
              <div className="video-holder" data-aos="fade-up">
                <div className="video-inn">
                  <a href="https://www.youtube.com/watch?v=AXRJzYTG3xQ" target="_blank" rel="noopener noreferrer">
                    <img src={img2} alt="I Am Cat Trailer" className="video-thumbnail" />
                    <img src={playButtonImg} alt="Play Button" className="play-button-img" />
                  </a>
                </div>
                <div className="video-title">
                  <h3>I Am Cat</h3>
                </div>
              </div>
            </Col>
            {/* Security*/}
            <Col lg={6} md={6}>
              <div className="video-holder" data-aos="fade-up">
                <div className="video-inn">
                  <a href="https://www.youtube.com/watch?v=Op4nC8Bn-v0" target="_blank" rel="noopener noreferrer">
                    <img src={img3} alt="Titans Clinic Trailer" className="video-thumbnail" />
                    <img src={playButtonImg} alt="Play Button" className="play-button-img" />
                  </a>
                </div>
                <div className="video-title">
                  <h3>I Am Security</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Media;
