import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../flaticon/flaticon.css";
import "./style.css";
import img_poster_titans from "../../img/Cover-Portrait.jpg"; // Update the path to your Titan Clinic image
import img_poster_cat from "../../img/Cover-Portrait2.jpg"; // Update the path to your I Am Cat image
import steamLogo from "../../img/steam-logo.png"; // Path to Steam logo
import appleLogo from "../../img/apple-logo.png"; // Path to Apple logo
import metaLogo from "../../img/meta-logo.png"; // Path to Meta logo
import appleStore from "../../img/apple-store.png";
import googlePlay from "../../img/google-play.jpg";
import picoLogo from "../../img/pico-logo.png";
import portrait_security from "../../img/portrait-iamsecurity.jpg";


const Subscribe = () => {
  return (
    <>
      <section className="subscribe-section section_100">
        <Container>
          <Row>
            
            <Col lg={6}>
              <div className="subscribe-box">
                <div className="poster-image">
                  <img src={img_poster_cat} alt="I Am Cat Poster" className="poster-image" />
                </div>
                <div className="section-title">
                  <h2>
                    I Am <span>Cat</span>
                  </h2>
                </div>
                <div className="available-title">
                  <h3>Available for purchase on:</h3>
                </div>
                <div className="button-container">
                <a href="https://www.meta.com/en-gb/experiences/6061406827268889/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                      <img src={metaLogo} alt="Meta Logo" className="platform-logo" />
                    </a>
                    <a href="https://store.steampowered.com/app/3016840/I_Am_Cat/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                      <img src={steamLogo} alt="Steam Logo" className="platform-logo" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.NewFolderGames.IAmCat" target="_blank" rel="noopener noreferrer">
                      <img src={googlePlay} alt="GooglePlay" className="platform-logo" />
                    </a>
                    <a href="https://apps.apple.com/by/app/i-am-cat-original-game/id6740168334" target="_blank" rel="noopener noreferrer">
                      <img src={appleStore} alt="AppleStore" className="platform-logo" />
                    </a>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="subscribe-box">
                <div className="poster-image">
                  <img src={portrait_security} alt="I Am Security" className="poster-image" />
                </div>
                <div className="section-title">
                  <h2>
                    I Am <span>Security</span>
                  </h2>
                </div>
                <div className="available-title">
                  <h3>Available for purchase on</h3>
                </div>
                <div className="button-container">
                <a href="https://www.meta.com/experiences/i-am-security/26261730413412240/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                      <img src={metaLogo} alt="Meta Logo" className="platform-logo" />
                    </a>
                    <a href="https://store.steampowered.com/app/3068720/I_Am_Security/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                      <img src={steamLogo} alt="Steam Logo" className="platform-logo" />
                    </a>
                    
                    <a href="https://play.google.com/store/apps/details?id=com.NewFolderGames.IAmSecurirty" target="_blank" rel="noopener noreferrer">
                      <img src={googlePlay} alt="GooglePlay" className="platform-logo" />
                    </a>
                    <a href="https://apps.apple.com/us/app/i-am-security-original-game/id6740168429" target="_blank" rel="noopener noreferrer">
                      <img src={appleStore} alt="AppleStore" className="platform-logo" />
                    </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Subscribe;
