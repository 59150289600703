import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import shape7 from "../../img/hero-dot-shape.png";
import videoTitans from "../../img/Trailer-wip.mp4";
import videoCat from "../../img/Trailer-wip-iamcat.mp4";
import videoSecurity from "../../img/Trailer-wip-iamsecurity.mp4"
import thumbnailTitans from "../../img/thumbnail_titans.jpg";
import thumbnailCat from "../../img/thumbnail_cat.jpg";
import thumbnailSecurity from "../../img/thumbnail_iamsecurity.jpg";
import steamLogo from "../../img/steam-logo.png";
import appleLogo from "../../img/apple-logo.png";
import metaLogo from "../../img/meta-logo.png";
import appleStore from "../../img/apple-store.png";
import googlePlay from "../../img/google-play.jpg";
import picoLogo from "../../img/pico-logo.png";


import "./style.css";

const Hero = () => {
  const videoSecurityRef = useRef(null);
  const videoCatRef = useRef(null);

  const handleMouseEnter = (videoRef) => {
    videoRef.current.play();
  };

  const handleMouseLeave = (videoRef) => {
    videoRef.current.pause();
  };

  return (
    <>
      <section className="hero-section">
        <Container>
          <Row className="align-items-center mb-4">
            {/* Section for I Am Cat */}
            <Col lg={6} md={12}>
              <div className="hero-right" data-aos="fade-left">
                <div
                  className="hero-video-container"
                  onMouseEnter={() => handleMouseEnter(videoCatRef)}
                  onMouseLeave={() => handleMouseLeave(videoCatRef)}
                >
                  <img src={thumbnailCat} alt="I Am Cat Thumbnail" className="thumbnail" />
                  <video ref={videoCatRef} src={videoCat} muted playsInline className="video" />
                  <div className="hero-dot-shape">
                    <img src={shape7} alt="shape" />
                  </div>
                </div>
                <div className="hero-left" data-aos="fade-right">
                  <h2>
                    <span className="underline">I Am Cat</span>
                  </h2>
                </div>
                <div className="available-on" data-aos="fade-right">
                  <span>Available on:</span>
                  <div className="buttons">
                    <a href="https://www.meta.com/en-gb/experiences/6061406827268889/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                      <img src={metaLogo} alt="Meta Logo" className="platform-logo" />
                    </a>
                    <a href="https://store.steampowered.com/app/3016840/I_Am_Cat/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                      <img src={steamLogo} alt="Steam Logo" className="platform-logo" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.NewFolderGames.IAmCat" target="_blank" rel="noopener noreferrer">
                      <img src={googlePlay} alt="GooglePlay" className="platform-logo" />
                    </a>
                    <a href="https://apps.apple.com/by/app/i-am-cat-original-game/id6740168334" target="_blank" rel="noopener noreferrer">
                      <img src={appleStore} alt="AppleStore" className="platform-logo" />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            {/* Section for I Am Security */}
            <Col lg={6} md={12} className="mb-4 mb-lg-0">
              <div className="hero-right" data-aos="fade-left">
                <div
                  className="hero-video-container"
                  onMouseEnter={() => handleMouseEnter(videoSecurityRef)}
                  onMouseLeave={() => handleMouseLeave(videoSecurityRef)}
                >
                  <img src={thumbnailSecurity} alt="I Am Security Thumbnail" className="thumbnail" />
                  <video ref={videoSecurityRef} src={videoSecurity} muted playsInline className="video" />
                  <div className="hero-dot-shape">
                    <img src={shape7} alt="shape" />
                  </div>
                </div>
                <div className="hero-left" data-aos="fade-right">
                  <h2>
                    <span className="underline">I Am Security</span>
                  </h2>
                </div>
                <div className="available-on" data-aos="fade-right">
                  <span>Available on:</span>
                  <div className="buttons">
                  <a href="https://www.meta.com/experiences/i-am-security/26261730413412240/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                      <img src={metaLogo} alt="Meta Logo" className="platform-logo" />
                    </a>
                    <a href="https://store.steampowered.com/app/3068720/I_Am_Security/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                      <img src={steamLogo} alt="Steam Logo" className="platform-logo" />
                    </a>
                    
                    <a href="https://play.google.com/store/apps/details?id=com.NewFolderGames.IAmSecurirty" target="_blank" rel="noopener noreferrer">
                      <img src={googlePlay} alt="GooglePlay" className="platform-logo" />
                    </a>
                    <a href="https://apps.apple.com/us/app/i-am-security-original-game/id6740168429" target="_blank" rel="noopener noreferrer">
                      <img src={appleStore} alt="AppleStore" className="platform-logo" />
                    </a>
                  </div>
                </div>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </>
  );
};

export default Hero;
